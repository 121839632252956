import './src/assets/styles/index.css';
import 'destyle.css/destyle.css';

const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

window.addEventListener('load', function() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries();
  for (const [key, value] of entries) {
    if (!UTM_KEYS.includes(key)) continue;
    document.cookie = `${key} = ${value}; path=/; domain=.increase.app; secure=true; SameSite=None`;
  }
});
