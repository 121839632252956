// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carreras-js": () => import("./../../../src/pages/carreras.js" /* webpackChunkName: "component---src-pages-carreras-js" */),
  "component---src-pages-clientes-js": () => import("./../../../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-contacto-enterprise-js": () => import("./../../../src/pages/contacto/enterprise.js" /* webpackChunkName: "component---src-pages-contacto-enterprise-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integraciones-js": () => import("./../../../src/pages/integraciones.js" /* webpackChunkName: "component---src-pages-integraciones-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-prensa-js": () => import("./../../../src/pages/prensa.js" /* webpackChunkName: "component---src-pages-prensa-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-partner-page-js": () => import("./../../../src/templates/PartnerPage.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

